import type { FeatureList } from './content/home';

export const DEV_LINKS = {
  sdk: {
    examples: 'https://developers.endaoment.org/sdk/examples',
    github: 'https://github.com/endaoment/endaoment-sdk',
    documentation: 'https://docs.endaoment.org/developers/getting-started/sdk-documentation',
  },
  sdkComponents: {
    documentation: 'https://www.npmjs.com/package/@endaoment/sdk-ui/v/0.0.4?activeTab=readme',
  },
  api: {
    examples: 'https://api.endaoment.org/oas',
    github: 'https://github.com/endaoment/endaoment-sdk',
    documentation: 'https://docs.endaoment.org/developers/getting-started/api-documentation',
  },
  subgraph: {
    examples:
      'https://thegraph.com/explorer/subgraphs/3iUnNee1poQFDRiZL3eLKZEajkfufKZ3kagMVczAsmPD?view=Overview&chain=mainnet',
    github: 'https://github.com/endaoment/endaoment-subgraph',
    documentation: 'https://docs.endaoment.org/developers/subgraph-documentation/subgraph-documentation',
  },
};

export const FEATURE_LIST: FeatureList = [
  {
    title: 'Find orgs easily',
    description:
      'Search Endaoment’s database of nonprofits by EIN, keyword or NTEE code to find the perfect place to give.',
  },
  {
    title: 'Quickly get contract addresses',
    description: 'Our API returns a ready-to-use Ethereum address for the nonprofit, along with rich metadata.',
  },
  {
    title: 'Discover public funds',
    description:
      'Users can also search & select from our community funds, and donor-advised funds that have been made public.',
  },
  {
    title: 'Create donation contract calls',
    description: 'We’ll format a donation transaction object with all the necessary input data for donating.',
  },
  {
    title: 'Link a private fund',
    description:
      'Pass the API a signed message from a wallet to get a list of contract addresses for private funds managed by a user.',
    comingSoon: true,
  },
  {
    title: 'Create grant contract calls',
    description: 'Format new grants from a signed in user to build in fund oversight directly into your app.',
    comingSoon: true,
  },
] as const;
