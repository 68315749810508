import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';

import { Card } from '@endaoment-frontend/ui/shared';

import folderIcon from '../images/icons/folder.png';
import apiLogo from '../images/logos/api.svg';
import graphLogo from '../images/logos/graph.svg';
import Logo from '../images/logos/logo.svg';
import sdkLogo from '../images/logos/sdk.svg';

import styles from './SdkCard.module.scss';

/* TODO: Re-add display of the SDK versioning */
// type NPMPackageInfoResponse = {
//   name: string;
//   author: {
//     name: string;
//   };
//   description: string;
//   homepage: string;
//   readme: string;
//   maintainers: {
//     name: string;
//     email: string;
//   }[];
//   repository: {
//     type: string;
//     url: string;
//   };
//   time: {
//     created: string;
//     modified: string;
//   } & Record<string, string>;
//   'dist-tags': { latest: string };
// };

// const fetchSdkVersion = async () => {
//   const res = await axios.get<NPMPackageInfoResponse>('https://registry.npmjs.org/@endaoment/sdk');
//   return res.data['dist-tags'].latest;
// };

export const SdkCard = ({ withIcon = false, className }: { withIcon?: boolean; className?: string }) => {
  // const { data: sdkVersion } = useQuery({ queryKey: ['sdkVersion'], queryFn: fetchSdkVersion });

  return (
    <Card className={clsx(styles.container, className)}>
      <p className={styles.text}>
        <Image src={Logo} height={34} width={180} alt='Endaoment developers logo' />
        <span>Plug in to our ecosystem</span>
      </p>
      <Link href='#sdk'>
        <Image src={sdkLogo} width={76} height={72} alt='' />
      </Link>
      <Link href='#api'>
        <Image src={apiLogo} width={76} height={72} alt='' />
      </Link>
      <Link href='#subgraph'>
        <Image src={graphLogo} width={76} height={72} alt='' />
      </Link>
      {!!withIcon && <Image src={folderIcon} alt='' width={100} height={100} className={styles.icon} />}
    </Card>
  );
};
