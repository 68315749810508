import Image from 'next/image';

import { Button, Pill, cardClassNames } from '@endaoment-frontend/ui/shared';

import { DocumentationButton } from '../../components/DocumentationButton';
import { GithubButton } from '../../components/GithubButton';
import { DEV_LINKS } from '../../editable';
import robotCharacter from '../../images/characters/robot.png';
import appleIcon from '../../images/icons/apple.png';
import botHeadIcon from '../../images/icons/bot-head.png';
import handshakeIcon from '../../images/icons/handshake.png';
import rainbowIcon from '../../images/icons/rainbow.png';
import rocketIcon from '../../images/icons/rocket.png';
import globeIcon from '../../images/icons/snowglobe.png';
import sdkLogo from '../../images/logos/sdk.svg';
import donateFundButtonShot from '../../images/shots/donate-fund-button.png';
import donateOrgButtonShot from '../../images/shots/donate-org-button.png';
import donationModalShot from '../../images/shots/donation-modal.png';
import searchModalShot from '../../images/shots/search-modal.png';

import styles from './SdkSection.module.scss';

const ComponentsSection = () => (
  <section className={styles['components-section']}>
    <Image
      src={donateFundButtonShot}
      width={390}
      alt='Donate Fund Button'
      className={styles['donate-fund-button-shot']}
    />
    <Image src={donateOrgButtonShot} width={343} alt='Donate Org Button' className={styles['donate-org-button-shot']} />
    <Image src={donationModalShot} width={345} alt='Donation Modal' className={styles['donation-modal-shot']} />
    <Image src={searchModalShot} width={450} alt='Search Modal' className={styles['search-modal-shot']} />
    <div>
      <Pill variation='purple' fill size='tiny'>
        New!
      </Pill>
      <h2>SDK React Components</h2>
      <p>
        Ready-made components for integrating donations, non-profit lookups, and formatting Endaoment smart contract
        interactions right into your application.
      </p>
      <DocumentationButton href={DEV_LINKS.sdkComponents.documentation} />
    </div>
  </section>
);

const BetweenLines = () => (
  <svg width='96' height='60' viewBox='0 0 96 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.5'
      d='M0.5 0V12.1633C0.5 20.9998 7.66344 28.1633 16.5 28.1633H79.5C88.3366 28.1633 95.5 35.3267 95.5 44.1633V60'
      stroke='#627EEA'
      strokeDasharray='5 5'
    />
  </svg>
);

const BackgroundLines = () => (
  <div className={styles['lines-container']}>
    <svg width='6000' height='925' viewBox='0 0 6000 925' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_6601_12251)'>
        <rect width='6000' height='925' fill='white' />
        <path opacity='0.5' d='M3000 925L3000 0' stroke='#627EEA' strokeDasharray='5 5' />
        <path opacity='0.5' d='M0 277L3000 276.999' stroke='#627EEA' strokeDasharray='5 5' />
        <path opacity='0.5' d='M3360 0L3360 925' stroke='#627EEA' strokeDasharray='5 5' />
      </g>
      <defs>
        <clipPath id='clip0_6601_12251'>
          <rect width='6000' height='925' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const SdkSection = () => {
  return (
    <>
      <section className={styles.wrapper} id='sdk'>
        <div className={styles.container}>
          <div>
            <Image src={sdkLogo} width={76} height={72} alt='' />
            <h2>An SDK for public good</h2>
            <p>
              Incorporating giving options into any dapp is easier than ever with our robust and flexible development
              kit.
            </p>
            <Button as='a' href={DEV_LINKS.sdk.examples} variation='fund' filled className={styles['examples-button']}>
              Explore examples
            </Button>
            <div className={styles.actions}>
              <GithubButton href={DEV_LINKS.sdk.github} />
              <DocumentationButton href={DEV_LINKS.sdk.documentation} />
            </div>
            <Image src={robotCharacter} alt='' width={280} height={500} className={styles['gumball']} />
          </div>
          <div>
            <div className={styles['icon-row']}>
              <Image src={rocketIcon} alt='' width={64} height={72} />
              <Image src={botHeadIcon} alt='' width={64} height={64} className={styles['mobile-only']} />
              <Image src={globeIcon} alt='' width={64} height={64} />
            </div>
            <ul className={styles.list}>
              <li className={cardClassNames.base}>
                <h3>Javascript + Typescript compatibility</h3>
                <Image src={handshakeIcon} alt='' width={64} height={49} />
                <p>Install our npm-compatible package directly into your application with a few lines of simple code</p>
              </li>
              <BetweenLines />
              <li className={cardClassNames.base}>
                <h3>Every compliant 501(c)(3) nonprofit</h3>
                <Image src={appleIcon} alt='' width={64} height={64} />
                <p>
                  We provide the largest rolodex of actively-monitored payment endpoints for nonprofits available–
                  2,600+ orgs and counting
                </p>
              </li>
              <BetweenLines />
              <li className={cardClassNames.base}>
                <h3>Built for Web3</h3>
                <Image src={rainbowIcon} alt='' width={64} height={64} />
                <p>
                  Add to your DAO stack, generate contract calls for nonprofit donations, programmatically split NFT
                  royalties to a cause
                </p>
              </li>
            </ul>
          </div>
        </div>
        <BackgroundLines />
        <ComponentsSection />
      </section>
    </>
  );
};
