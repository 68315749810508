import Head from 'next/head';

import { GetFeaturedOrgs } from '@endaoment-frontend/api';
import { makeDehydratedQueriesString } from '@endaoment-frontend/data-fetching';

import { ApiSection, Hero, SdkLink, SdkSection, SubgraphSection } from '../content/home';

export const getStaticProps = async () => {
  return {
    props: {
      dehydratedState: await makeDehydratedQueriesString(GetFeaturedOrgs.getDehydratedQueryArgs([8])),
    },
  };
};

export const HomePage = () => {
  return (
    <>
      <Head>
        <title>Donation SDK | Nonprofit App Developers | Development Kit | Endaoment</title>
        <meta
          name='description'
          content='Enable users to donate crypto, stocks and assets directly with a few short lines of code. Integrate impact directly into your app or website.'
        />

        <meta property='og:url' content='https://developers.endaoment.org' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Donation SDK | Nonprofit App Developers | Development Kit | Endaoment' />
        <meta
          property='og:description'
          content='Enable users to donate crypto, stocks and assets directly with a few short lines of code. Integrate impact directly into your app or website.'
        />
        <meta property='og:keywords' content='Donation SDK, Nonprofit App Developers, Nonprofit development kit' />
        <meta property='og:image' content='https://developers.endaoment.org/images/developers-meta.png' />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='developers.endaoment.org' />
        <meta property='twitter:url' content='https://developers.endaoment.org' />
        <meta name='twitter:title' content='Endaoment — Developers' />
        <meta
          name='twitter:description'
          content='Send assets to tax-exempt funds and over 1.5 million nonprofits onchain via the Endaoment SDK'
        />
        <meta name='twitter:image' content='https://developers.endaoment.org/images/developers-meta.png' />
      </Head>

      <Hero />
      <SdkSection />
      <ApiSection />
      <SubgraphSection />
      <SdkLink />
    </>
  );
};

export default HomePage;
